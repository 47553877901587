import React from "react"

const Banner = (props) => (

    <section className="homeBannerSection innerPageBanner">
            <div className="homeBannerContent">
            <div className="container">
                <h1><span className="colorBlue">{props.title ? props.title : `IELTS Academic` } </span> {props.subtitle ? props.subtitle : `Mock Test`}</h1>  
                <ul>
                <li>Developed By Experts</li>
                <li>Simulated Test Engine</li>
                <li>Instant Results</li>
                </ul>
            </div>
        </div>
    </section> 
)

export default Banner
