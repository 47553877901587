import React from "react"
import LaunchTestButton from "../LaunchTestButton"

const ReadingModule = (props) => (
    <section className="lrws-section-1">
        <div className="container">
        <div className="listening-wrapper mt-5">
              <div className="row">
                <div className="col-md-4">
                  <div className="listening-image-wrapper">
                    <img src={require('../../images/reading-image.png')} alt="" />
                  </div>
                </div>
                <div className="col-md-8" >
                  <div className="listening-content-wrapper">
                    <h3 className="content-title">
                      Reading
                    </h3>
                    <ul>
                      <li>
                        <span>
                          <img src={require('../../images/questions.png')} alt="" />
                          <strong>Questions : </strong> 40 
                        </span>
                      </li>
                      <li>
                        <span>
                          <img src={require('../../images/duration.png')} alt="" />
                          <strong>Duration : </strong> 60 min
                        </span>
                      </li>
                    </ul>
                    <div className="listening-sections-wrapper">
                      <p className="content-sub-title pb-0">Sections</p>
                      <p className="note">The task types may include: multiple choice questions, identifying information, Identifying writer’s views/claims, Matching information, Matching headings, matching features, matching sentence endings, sentence completion, Summary, note, table, fow-chart completion, Diagram label completion, and Short-answer questions. </p>
                    </div>
                    <p className="content-disclaimer mb-0">Each question carries one mark.</p>
                    <p className="content-disclaimer" style={{color: '#000'}}><strong>Scoring scale:</strong> <span style={{fontSize: '15px'}}>0-9 brands</span></p>
                    <div className="d-flex align-items-center">
                      <LaunchTestButton testId = {props.testId} courseId = {props.courseId} classList="trymock-btn btn" text="TRY MOCK TEST" />
                      <div className="free-label">FREE!</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
)

export default ReadingModule
