import React from "react"
import LaunchTestButton from "../LaunchTestButton"

const RecomendedCourses = (props) => (
 <section className="lrws-section-2">
    <div className="container">
      <h2>Recommended Courses for IELTS Academic</h2>
      <div className="row">
        <div className="col-md-4">
          <div className="lrws-section-2-img-wrapper">
            <img src={require('../../images/ielts-academic.png')} alt="" />
          </div>
        </div>
        <div className="col-md-8">
          <div className="lrws-section-2-content-wrapper">
            <h4 className="lrws-section-2-title">Kanan IELTS Academic</h4>
            <p className="lrws-section-2-description">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae.</p>
            <ul>
              <li>DIAGNOSTIC TEST</li>
              <li>CONCEPT BUILDERS</li>
              <li>LIVE WEBINARS</li>
              <li>E-MENTORING</li>
              <li>VIDEO LESSONS</li>
              <li>MOCK TESTS</li>
              <li>SIMULATED TESTING</li>
              <li>SUPPORT</li>
            </ul>
            <LaunchTestButton testId={props.testId} courseId={props.courseId} text="Enroll" classList="enroll-btn btn" />
          </div>
        </div>
      </div>
    </div>
  </section>     
)

export default RecomendedCourses
