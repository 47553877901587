import React, {useState} from 'react'
import {Button} from 'react-bootstrap'
import RegisterModal from './Modal/RegisterModal'
import { getCurrentUser} from "../utils/auth"
import {navigate} from 'gatsby'
import {kananBaseURL} from '../config'

const LaunchTestButton = props => {

    const [launch, setLaunch] = useState({
        isLaunch: false,
     })
     
     const enablePopup = () => {
        setLaunch((prevState) => {
          return { ...prevState, isLaunch: true};
          });
      }
    
    const closePopup = () => {
        setLaunch((prevState) => {
          return { ...prevState, isLaunch: false};
          });
    }

    const currentUser = getCurrentUser();
    const loginId = Object.keys(currentUser).length > 0 ? currentUser.userData.loginId : ''

    /*Test Launch*/
    const LaunchTest = () => {

        const currentUser = localStorage.getItem('tryMockUser');
        const userData = JSON.parse(currentUser)
        const token = userData ? userData.token : null
    
        const launch_options = {
          "testId" : props.testId,
          "courseId" : props.courseId,
          "rurl" : window.location.origin,
          "groupId" : "0",
        } 
       
        IELTS_Test_Launch(launch_options, token)
        
      }
    
      const IELTS_Test_Launch = (options, token) => {
        document.getElementById('launchButton').innerHTML = 'Please Wait...'  
        fetch(`${kananBaseURL}/kanan/generate-token`, {
          method: 'post',
          headers: {
            "Content-Type": "application/json",
            "Authorization" : token
          },
          body: JSON.stringify(options)
        }).then(function(response) {
          return response.json();
        }).then(function(res) {
    
          if(res.success) {
            navigate(res.data.redirectUrl)
          } else {
            console.error(res)
          }
          document.getElementById('launchButton').innerHTML = 'Launch Test'  
    
        });
      }
    /*Test Launch*/

    return(
        <React.Fragment>
           {launch.isLaunch && (<RegisterModal testId={props.testId} courseId={props.courseId} open={launch.isLaunch} close={closePopup} isTestLaunch={true} />)}
           {
               loginId ? <Button id="launchButton" className={props.classList} onClick={() => LaunchTest()}>Launch Test</Button> :
               <Button className={props.classList} onClick={() => enablePopup()}>{props.text}</Button>
           }
        </React.Fragment>
    )
}

export default LaunchTestButton