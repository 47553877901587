import React from "react"
import {Link} from 'gatsby'

const Menu = () => (
    <div className="courseMenu">
         <div className="container">
          <ul>
              <li><Link to="/ielts-academic/listening/" activeClassName="active">Listening</Link></li>
              <li><Link to="/ielts-academic/reading/" activeClassName="active">Reading</Link></li>
              <li><Link to="/ielts-academic/writing/" activeClassName="active">Writing</Link></li>
              <li><Link to="/ielts-academic/speaking/" activeClassName="active">Speaking</Link></li>
          </ul>
         </div>   
    </div>
)

export default Menu
