import React from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Banner from "../../components/Common/Banner"
import Menu from "../../components/IELTS_Academic/Menu"
import RecomendedCourses from "../../components/IELTS_Academic/RecomendedCourses"
import ReadingModule from "../../components/IELTS_Academic/ReadingModule"

const testId = '02e88985bc6ae4e246a31ea5c69ba08e'
const courseId = '695431377ee45b963cebc7fc57eb7669'

const Reading = () => (
  <Layout>
    <SEO title="IELTS Academic - Reading" />
    <main>
        <Banner />
        <Menu />
        <ReadingModule testId={testId} courseId={courseId} />
        <RecomendedCourses testId={testId} courseId={courseId} />
    </main>
  </Layout>
)

export default Reading
